<template>
  <div class="form--group amenity--section row">
    <label class="col-12 col-lg-3">{{ $t('project.contact') }}</label>
    <div class="col-12 col-lg-6">
      <contact-field
        v-for="(contact, index) in contacts"
        :key="index"
        :index="index"
        :phone="contact.phone"
        ref="contactField"
        :nearbyPlaceLength="contacts.length"
      />
      <div class="col-12 p-0 mt-2" v-if="contacts.length < 3">
        <v-btn color="primary" @click="addContact" type="button" id="contactBtn">
          {{ $t('project.btn.addContact') }}
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
const ContactField = () =>
  import('@/components/project-management/project/form/contact/contact-field');
import { mapState } from 'vuex';

export default {
  name: 'contact-section',
  components: {
    ContactField,
  },
  computed: {
    ...mapState({
      contacts: (state) => state.project.form.contacts,
    }),
  },
  methods: {
    addContact() {
      this.$store.commit('project/form/ADD_CONTACT');
    },
    async validate() {
      const validation = this.$refs.contactField.map(function (form) {
        return form.validate();
      });
      let results = await Promise.all(validation);
      return (
        results.filter(function (result) {
          return !result;
        }).length === 0
      );
    },
  },
};
</script>

<style scoped></style>
